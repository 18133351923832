import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/create-rails-5-api-application",
  "date": "2016-08-08",
  "title": "CREATE A RAILS 5 API APPLICATION",
  "author": "admin",
  "tags": ["development", "rails", "ruby"],
  "featuredImage": "feature.jpg",
  "excerpt": "Rails 5 ships a new gem rails-api which makes building API only rails apps a breeze. ActiveRecord is a part of Rails 5 along with a strong asset pipeline, making Rails a great option for creating APIs."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We start by settings up Rails 5.`}</p>
    <h2>{`Setting up Rails 5`}</h2>
    <h3>{`Ruby 2.2.2+`}</h3>
    <p>{`Make sure you have Ruby 2.2.2 or above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`ruby -v
`}</code></pre>
    <p>{`If you don’t have the required minimum version, this link will help you to get up to date:`}</p>
    <ul>
      <li parentName="ul">{`Mac - `}<a parentName="li" {...{
          "href": "https://railsapps.github.io/installrubyonrails-mac.html"
        }}>{`install Ruby on Rails for Mac`}</a>{` `}</li>
      <li parentName="ul">{`Windows – `}<a parentName="li" {...{
          "href": "http://railsinstaller.org/en"
        }}>{`Rails Installer`}</a></li>
    </ul>
    <h2>{`Create an App`}</h2>
    <p>{`We need to pass the `}<inlineCode parentName="p">{`–api`}</inlineCode>{` option at the time we create a new app.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`rails new contacts_api –api
`}</code></pre>
    <h2>{`Tests`}</h2>
    <p>{`We are going to use `}<inlineCode parentName="p">{`RSpec`}</inlineCode>{` for testing our API.`}</p>
    <p>{`Add the following lines in your `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{`, in the :development, :test area`}</p>
    <pre><code parentName="pre" {...{}}>{`# Use RSpec for specs
gem 'rspec-rails', '3.1.0'
# Use Factory Girl for generating random test data
gem 'factory_girl_rails'
`}</code></pre>
    <p>{`Now change into the directory, and run following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`bundle
`}</code></pre>
    <p>{`Run the `}<inlineCode parentName="p">{`RSpec`}</inlineCode>{` installer`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`bin/rails g rspec:install
`}</code></pre>
    <h3>{`Specs Not Tests`}</h3>
    <p>{`We will delete the `}<inlineCode parentName="p">{`test`}</inlineCode>{` directory in Rails as we are writing specs instead of tests.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`rm -rf test
`}</code></pre>
    <h2>{`Scaffolding API`}</h2>
    <p>{`We use the default scaffold geenerators to create API resources.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`bin/rails g scaffold user name email
`}</code></pre>
    <p>{`We can create resources just like this. Once you have done that, you can migrate and run the app.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`bin/rake db:migrate
`}</code></pre>
    <p>{`Our new API will be up and running on `}<inlineCode parentName="p">{`http://localhost:3000`}</inlineCode></p>
    <h2>{`CORS`}</h2>
    <p>{`When we are building public APIs, we ned enable Cross-Origin Resource Sharing(CORS).`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` add `}<inlineCode parentName="p">{`rack-cors`}</inlineCode>{` gem to enable this for us.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`gem ‘rack-cors’
`}</code></pre>
    <p>{`And now we can update the bundle:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`bundle
`}</code></pre>
    <p>{`To enable `}<inlineCode parentName="p">{`GET`}</inlineCode>{`, `}<inlineCode parentName="p">{`POST`}</inlineCode>{`, or `}<inlineCode parentName="p">{`OPTIONS`}</inlineCode>{` requests from any origin on any source, we will add the code snippet below to `}<inlineCode parentName="p">{`config/application.rb`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-rb"
      }}>{`module ContactsApi
  class Application < Rails::Application
 
    # ...
 
    config.middleware.insert_before 0, "Rack::Cors" do
      allow do
        origins '*'
        resource '*', :headers => :any, :methods => [:get, :post, :options]
      end
    end
 
  end
end
`}</code></pre>
    <h2>{`Throttling`}</h2>
    <p>{`To stop DDos and brute force attacks we are going to use a Rahe middleware called Rack::Attack.`}</p>
    <pre><code parentName="pre" {...{}}>{`gem 'rack-attack'
`}</code></pre>
    <p>{`And update your bundle`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`gem ‘rack-attack’
`}</code></pre>
    <p>{`And now update your `}<inlineCode parentName="p">{`config/application.rb`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`module ContactsApi
  class Application < Rails::Application
 
    # ...
 
    config.middleware.use Rack::Attack
 
  end
end
`}</code></pre>
    <p>{`To configure `}<inlineCode parentName="p">{`Rack::Attack`}</inlineCode>{` rules, create a file in c`}<inlineCode parentName="p">{`onfig/initializers/rack_attack.rb`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`class Rack::Attack
 
  # \`Rack::Attack\` is configured to use the \`Rails.cache\` value by default,
  # but you can override that by setting the \`Rack::Attack.cache.store\` value
  Rack::Attack.cache.store = ActiveSupport::Cache::MemoryStore.new
 
  # Allow all local traffic
  whitelist('allow-localhost') do |req|
    '127.0.0.1' == req.ip || '::1' == req.ip
  end
 
  # Allow an IP address to make 5 requests every 5 seconds
  throttle('req/ip', limit: 5, period: 5) do |req|
    req.ip
  end
 
  # Send the following response to throttled clients
  self.throttled_response = ->(env) {
    retry_after = (env['rack.attack.match_data'] || {})[:period]
    [
      429,
      {'Content-Type' => 'application/json', 'Retry-After' => retry_after.to_s},
      [{error: "Throttle limit reached. Retry later."}.to_json]
    ]
  }
end
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`This guide will help you kick start your first Rails 5 API application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      